import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { OperatorSelectOption, SqlWhereExpression, WhereFilterConfig } from '../../models';
import { IWhereFilter } from '../base-filters';

export type NumberFilterOperator = 'eq' | 'lt' | 'lte' | 'gt' | 'gte' | 'between' | 'jsoncontains';

export interface NumberFilterConfig extends WhereFilterConfig {
  operator: OperatorSelectOption[];
  defaultOperator?: NumberFilterOperator;
  predefinedValues?: number[];
  min?: number;
  max?: number;
  step?: number;
}

export class NumberFilter extends IWhereFilter<NumberFilterConfig> {
  private get opratorFiledKey() {
    return `${this.fieldKey}_number_operator`;
  }

  private get toFieldKey() {
    return `${this.fieldKey}_to`;
  }

  private get defaultOperator(): NumberFilterOperator {
    return this.config.defaultOperator ?? 'eq';
  }

  labelTr$ = combineLatest([tr('condition_for'), tr(this.config.title)]).pipe(map(([condition, title]) => `${condition} ${title}`));

  override createFields(): FormlyFieldConfig[] {
    const operatorFieldKey = this.opratorFiledKey;
    const isBetween = (model: Record<string, unknown>) => {
      return model[operatorFieldKey] === 'between';
    };

    return [
      {
        fieldGroupClassName: 'd-flex align-items-stretch gap-2',
        fieldGroup: [
          {
            className: 'col-1',
            key: this.opratorFiledKey,
            type: 'select',
            defaultValue: this.defaultOperator,
            expressions: {
              'props.label': this.labelTr$,
            },
            props: {
              options: this.operatorsOptionsToSelectTr$(this.config.operator),
            },
          },
          {
            className: 'col-1',
            key: this.fieldKey,
            type: 'input-hint',
            expressions: {
              'props.label': tr(this.config.title ?? ''),
              'props.placeholder': tr(this.config.placeholder ?? ''),
            },
            props: {
              type: 'number',
              options: this.config.predefinedValues ?? [],
              min: this.config.min,
              max: this.config.max,
              step: this.config.step,
              attributes: {
                autocomplete: 'off',
              },
            },
          },
          {
            className: 'col-1',
            key: this.toFieldKey,
            type: 'input-hint',
            expressions: {
              'props.label': tr('to'),
              'props.placeholder': tr('to'),
            },
            props: {
              type: 'number',
              options: this.config.predefinedValues ?? [],
              min: this.config.min,
              max: this.config.max,
              step: this.config.step,
              attributes: {
                autocomplete: 'off', // Add this line to disable browser auto-complete
              },
            },
            hideExpression: (model) => !isBetween(model),
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] {
    const whereExpressions: WhereExpression[] = [];

    const value = this.getValue<number | null>(data, this.fieldKey);
    const toValue = this.getValue<number | null>(data, this.toFieldKey);
    const operator = (data[this.opratorFiledKey] as NumberFilterOperator) ?? this.defaultOperator;

    if (value) {
      if (operator === 'jsoncontains') {
        return [
          {
            operator: operator,
            field: this.config.field,
            value: this.isJsonDataFilter() ? this.getJsonDataValue(value) : value,
          },
        ];
      }

      if (operator === 'between') {
        if (value == null || toValue == null) {
          return [];
        }
        return [
          {
            condition: 'and',
            predicates: [
              {
                operator: 'gte',
                field: this.field,
                value: value,
              },
              {
                operator: 'lte',
                field: this.field,
                value: toValue,
              },
            ],
          },
        ];
      }
      switch (operator) {
        case 'eq':
        case 'lt':
        case 'lte':
        case 'gt':
        case 'gte':
          whereExpressions.push({
            operator: operator,
            field: this.config.field,
            value: value,
          });
          break;
        default:
          console.error(`Number filter - unsupported operator: ${operator}`);
          break;
      }
    }
    return whereExpressions;
  }

  override createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[] {
    const value = this.getValue<number | null>(data, this.fieldKey);
    return value
      ? [
          {
            field: this.config.field,
            operator: (data[this.opratorFiledKey] as NumberFilterOperator) ?? this.defaultOperator,
            value: value,
          },
        ]
      : [];
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const value = data[this.fieldKey] as number | undefined;
    if (!value) return '';
    const operator = this.getValue<NumberFilterOperator | null>(data, this.opratorFiledKey);
    const title = await firstValueFrom(tr(this.config.title));
    if (operator === 'between') {
      const toValue = data[this.toFieldKey] as number | undefined;
      return `${title} ${this.getOperatorTitle(operator)} ${value} - ${toValue}`;
    }
    return `${title} ${this.getOperatorTitle(operator)} ${value}`;
  }

  private getOperatorTitle(operator?: NumberFilterOperator | null): string {
    if (!operator) return '';
    switch (operator) {
      case 'eq':
        return '=';
      case 'gt':
        return '>';
      case 'gte':
        return '>=';
      case 'lt':
        return '<';
      case 'lte':
        return '<=';
      case 'between':
        return 'between';
      case 'jsoncontains':
        return '=';
      default:
        return '';
    }
  }
}
