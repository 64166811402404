import { WhereExpression, WhereOperator } from '@npm-libs/ng-templater';
import moment from 'moment';
import { SqlWhereExpression } from '../../../../models';
import { DateFilterOperator } from '../date-filter';
import { DateExpression } from './base';

export class TimeExpression extends DateExpression {
  constructor(field: string, operator: DateFilterOperator, date: Date | undefined, toDate: Date | undefined) {
    super(field, operator, date, toDate);
  }

  override createGqlExpression(): WhereExpression | undefined {
    return this.createTimeExpression(this.field, this.operator, this.date) as WhereExpression;
  }

  override createSqlExpression(): SqlWhereExpression | undefined {
    return undefined;
  }

  private createTimeExpression(field: string, operator: DateFilterOperator, dateTime?: Date) {
    if (!dateTime) return undefined;

    const todayStartDay = moment().startOf('day').toISOString();
    const todayEndDate = moment().endOf('day').toISOString();
    const providedDatetime = moment(dateTime);
    const compareDatetime = moment()
      .startOf('day')
      .hours(providedDatetime.hours())
      .minutes(providedDatetime.minutes())
      .seconds(providedDatetime.seconds())
      .toISOString();

    switch (operator) {
      case 'time-today-gte':
        return {
          condition: 'and',
          predicates: [
            {
              field,
              operator: 'gte' as WhereOperator,
              value: compareDatetime,
            },
            {
              field,
              operator: 'lte' as WhereOperator,
              value: todayEndDate,
            },
          ],
        };
      case 'time-today-lte':
        return {
          condition: 'and',
          predicates: [
            {
              field,
              operator: 'gte' as WhereOperator,
              value: todayStartDay,
            },
            {
              field,
              operator: 'lte' as WhereOperator,
              value: compareDatetime,
            },
          ],
        };
    }
    return undefined;
  }
}
