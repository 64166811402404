import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom } from 'rxjs';
import { OperatorSelectOption, RawExpression, SqlWhereExpression, WhereFilterConfig } from '../../models';
import { IWhereFilter } from '../base-filters';
import { RawExpressionFilter } from '../expressions';

export type LogictFilterOperator = 'eq' | 'neq' | 'indeterminate';

export interface LogicFilterConfig extends WhereFilterConfig {
  operator: OperatorSelectOption[];
  defaultOperator?: LogictFilterOperator;
  trueWhereExpression?: RawExpression;
  falseWhereExpression?: RawExpression;
}

export class LogicFilter extends IWhereFilter<LogicFilterConfig> {
  override createFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'd-flex align-items-stretch gap-2',
        fieldGroup: [
          {
            className: 'col-1',
            key: this.fieldKey,
            type: 'radio',
            defaultValue: this.config.defaultOperator ?? this.operatorsToSelectOptions(this.config.operator)[0],
            expressions: {
              'props.label': tr(this.config.title),
              'props.placeholder': tr(this.config.placeholder ?? ''),
            },
            props: {
              options: this.operatorsOptionsToSelectTr$(this.config.operator),
            },
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] | WhereExpression {
    const operator = this.getValue<LogictFilterOperator | null | undefined>(data, this.fieldKey);
    if (operator === 'eq') {
      if (this.config.trueWhereExpression?.whereExpression?.raw) {
        return new RawExpressionFilter(this.dataContext, this.config.trueWhereExpression).createGqlExpression();
      }

      return {
        operator: 'eq',
        field: this.config.field,
        value: true,
      };
    }

    if (operator === 'neq') {
      if (this.config.falseWhereExpression?.whereExpression?.raw) {
        return new RawExpressionFilter(this.dataContext, this.config.falseWhereExpression).createGqlExpression();
      }

      return {
        condition: 'not',
        predicates: [
          {
            operator: 'eq',
            field: this.config.field,
            value: true,
          },
        ],
      };
    }

    return [];
  }

  override createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[] {
    const operator = this.getValue<LogictFilterOperator | null | undefined>(data, this.fieldKey);
    return operator
      ? [
          {
            field: this.config.field,
            operator: operator ?? null,
            value: operator,
          },
        ]
      : [];
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const operator = this.getValue<LogictFilterOperator | null>(data, this.fieldKey);
    const translations = await firstValueFrom(combineLatest({ title: tr(this.config.title), yes: tr('yes'), no: tr('no') }));
    return operator && operator !== 'indeterminate'
      ? `${translations.title}: ${operator === 'eq' ? `${translations.yes}` : `${translations.no}`}`
      : '';
  }
}
