import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { SelectOption } from '@rcg/core';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { OperatorSelectOption, SqlWhereExpression, WhereFilterConfig } from '../../models';
import { IWhereFilter } from '../base-filters';

export type SelectFilterOperator = 'eq' | 'ne';

export interface SelectFilterConfig extends WhereFilterConfig {
  operator: OperatorSelectOption[];
  defaultOperator?: SelectFilterOperator;
  options: SelectOption[];
  translateOptions?: boolean;
}

export class SelectFilter extends IWhereFilter<SelectFilterConfig> {
  private get opratorFiledKey() {
    return `${this.fieldKey}_select_operator`;
  }

  private get defaultOperator(): SelectFilterOperator {
    return this.config.defaultOperator ?? 'eq';
  }

  labelTr$ = combineLatest([tr('condition_for'), tr(this.config.title)]).pipe(map(([condition, title]) => `${condition} ${title}`));

  override createFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'd-flex align-items-stretch gap-2',
        fieldGroup: [
          {
            className: 'col-1',
            key: this.opratorFiledKey,
            type: 'select',
            defaultValue: this.defaultOperator,
            expressions: {
              'props.label': this.labelTr$,
            },
            props: {
              options: this.operatorsOptionsToSelectTr$(this.config.operator),
            },
          },
          {
            className: 'col-2',
            key: this.fieldKey,
            type: 'select',
            expressions: {
              'props.label': tr(this.config.title),
              'props.placeholder': tr(this.config.placeholder ?? ''),
            },
            props: {
              options: this.optionsTr$(this.config.options ?? []),
            },
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] {
    const whereExpressions: WhereExpression[] = [];

    const value = this.getValue<number | string | null>(data, this.fieldKey);
    const operator = (data[this.opratorFiledKey] as SelectFilterOperator) ?? this.defaultOperator;

    if (value) {
      switch (operator) {
        case 'eq':
        case 'ne':
          whereExpressions.push({
            operator: operator,
            field: this.config.field,
            value: value,
          });
          break;
        default:
          console.error(`Select filter - unsupported operator: ${operator}`);
          break;
      }
    }
    return whereExpressions;
  }

  override createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[] {
    const value = this.getValue<number | string | null>(data, this.fieldKey);
    return value
      ? [
          {
            field: this.config.field,
            operator: (data[this.opratorFiledKey] as SelectFilterOperator) ?? this.defaultOperator,
            value: value,
          },
        ]
      : [];
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const value = data[this.fieldKey] as number | string | undefined;

    if (!value) return '';
    const operator = this.getValue<SelectFilterOperator | null>(data, this.opratorFiledKey);
    const option = this.config.options.find((o) => o.value === value);
    const operatorTitle = operator === 'ne' ? '!=' : operator === 'eq' ? '=' : '';
    const title = await firstValueFrom(tr(this.config.title));
    const label = await firstValueFrom(tr(option?.label ?? ''));
    return `${title} ${operatorTitle} ${label ?? value}`;
  }
}
