import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { SqlWhereExpression, WhereFilterConfig } from '../../models';
import { IWhereFilter } from '../base-filters';

export interface NumericRangeFilterConfig extends WhereFilterConfig {
  min?: number;
  max?: number;
  singleValueLabel?: string;
  startLabel?: string;
  endLabel?: string;
  filterDescription?: string;
}

export class NumericRangeFilter extends IWhereFilter<NumericRangeFilterConfig> {
  private get operatorKey() {
    return `${this.fieldKey}_numeric_range_operator`;
  }

  private get startKey() {
    return `${this.fieldKey}_numeric_range_start`;
  }

  private get endKey() {
    return `${this.fieldKey}_numeric_range_end`;
  }

  private get min() {
    return this._config.min ?? 0;
  }

  private get max() {
    return this._config.max ?? 5;
  }

  labelTr$ = combineLatest([tr('condition_for'), tr(this.config.title)]).pipe(map(([condition, title]) => `${condition} ${title}`));

  options = [
    { label: 'range', value: 'range' },
    { label: 'exact_value', value: 'single' },
  ];
  override createFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'd-flex align-items-stretch gap-2',
        fieldGroup: [
          {
            className: 'col-1',
            key: this.operatorKey,
            type: 'select',
            defaultValue: 'range',
            props: {
              options: this.optionsTr$(this.options),
            },
            expressions: {
              'props.label': this.labelTr$,
            },
          },
          {
            className: 'col-1',
            key: this.startKey,
            type: 'input',
            props: {
              type: 'number',
              min: this.min,
              max: this.max,
            },
            expressions: {
              'props.label': tr(this._config.startLabel ?? ''),
              'props.placeholder': tr(this._config.startLabel ?? ''),
            },
          },
          {
            className: 'col-1',
            key: this.endKey,
            type: 'input',
            props: {
              type: 'number',
              min: this.min,
              max: this.max,
            },
            expressions: {
              'props.label': tr(this._config.endLabel ?? ''),
              'props.placeholder': tr(this._config.endLabel ?? ''),
            },
            hideExpression: (model) => model[this.operatorKey] === 'single',
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] {
    const whereExpressions: WhereExpression[] = [];
    const startNum = this.getValue<number | undefined>(data, this.startKey);
    const endNum = this.getValue<number | undefined>(data, this.endKey);
    const operatorValue = this.getValue<string | undefined>(data, this.operatorKey);

    if (!startNum && !endNum) return whereExpressions;

    if (operatorValue === 'single') {
      if (!startNum) return whereExpressions;
      return [
        {
          operator: 'eq',
          field: this.field,
          value: startNum,
        },
      ];
    }

    if (!startNum || !endNum) return whereExpressions;

    if (startNum > endNum) return whereExpressions;

    return [
      {
        condition: 'and',
        predicates: [
          {
            operator: 'gte',
            field: this.field,
            value: startNum,
          },
          {
            operator: 'lte',
            field: this.field,
            value: endNum,
          },
        ],
      },
    ];
  }

  override createSqlFilterExpression(): SqlWhereExpression[] {
    return [];
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const startNum = this.getValue<number | undefined>(data, this.startKey);
    const endNum = this.getValue<number | undefined>(data, this.endKey);
    const operatorValue = this.getValue<string | undefined>(data, this.operatorKey);

    const title = await firstValueFrom(tr(this._config.title));

    if (operatorValue === 'single' && startNum) return `${title} ${startNum}`;
    if (startNum && endNum && startNum <= endNum) return `${title} ${startNum} - ${endNum}`;
    return '';
  }
}
