import { WhereExpression, WhereOperator } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '../../../../models';
import { DateFilterOperator } from '../date-filter';
import { DateExpression } from './base';

export class DateTimeExpression extends DateExpression {
  constructor(field: string, operator: DateFilterOperator, date: Date | undefined, toDate: Date | undefined) {
    super(field, operator, date, toDate);
  }

  override createGqlExpression(): WhereExpression | undefined {
    return this.createDatetimeExpression(this.field, this.operator, this.date, this.toDate);
  }

  override createSqlExpression(): SqlWhereExpression | undefined {
    return undefined;
  }

  private createDatetimeExpression(field: string, operator: DateFilterOperator, dateTime?: Date, toDateTime?: Date) {
    if (!dateTime) return undefined;
    const isoDateTime = dateTime.toISOString();
    switch (operator) {
      case 'dt-eq':
        return { field, operator: 'eq' as WhereOperator, value: isoDateTime };
      case 'dt-gte':
        return { field, operator: 'gte' as WhereOperator, value: isoDateTime };
      case 'dt-lte':
        return { field, operator: 'lte' as WhereOperator, value: isoDateTime };
      case 'dt-between':
        if (!toDateTime) return undefined;
        return {
          condition: 'and',
          predicates: [
            { field, operator: 'gte' as WhereOperator, value: isoDateTime },
            { field, operator: 'lte' as WhereOperator, value: toDateTime.toISOString() },
          ],
        } as WhereExpression;
    }
    return undefined;
  }
}
