import { Injectable, inject } from '@angular/core';
import { AuthService } from '@rcg/auth';
import { FilterExpressions } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { MessageService } from '@rcg/standalone';
import { catchError, concat, filter, firstValueFrom, from, map, of, switchMap, take } from 'rxjs';
import { createFilter } from '../filters';
import { deleteFavorite, favoritesQuery, favoritesSubscription, insertFavorite, updateFavorite } from '../gql/favorites.gql';
import { Favorite } from '../models';
import { FilterExpressionService } from './filtters-builder.service';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
  private readonly graphQlClient = inject(GraphqlClientService);
  private readonly messageService = inject(MessageService);
  private readonly auth = inject(AuthService);
  filterExpressionService = inject(FilterExpressionService);

  getFavoritesQueryFirstThenSubscription$(moduleId: number) {
    return concat(this.getFavorites$(moduleId, false).pipe(take(1)), this.getFavorites$(moduleId, true)).pipe(
      switchMap((favorites) => {
        const promises = favorites.map((favorite) => this.recreatePeriodicDateFilters(favorite));
        return from(Promise.all(promises));
      }),
    );
  }

  async deleteFavorite(id: number, favoriteUserId: number) {
    if (!id || !favoriteUserId) throw new Error(`No favorite id or owner id. Id:${id} - OwnerId:${favoriteUserId}`);
    try {
      const isOwner = (await firstValueFrom(this.auth.user$))?.id === favoriteUserId;

      if (!isOwner) {
        this.messageService.showWarningSnackbar(`No permissions to delete favorite. Only owner can delete favorite`);
        return;
      }
      const resultId = await firstValueFrom(
        this.graphQlClient.mutate<{ data?: { id: number | undefined } | undefined }>({
          mutation: deleteFavorite,
          variables: {
            id: id,
          },
        }),
      );
      if (!resultId?.data?.id) throw new Error('Not deleted - no result id');
      this.messageService.showInfoSnackbar(`Favorite ${id} deleted ${id}:`);
    } catch (error) {
      this.messageService.showErrorSnackbar(`Error delete favorite ${id}: ${error}`);
    }
  }

  async saveFavorite(input: {
    moduleId: number;
    filters: FilterExpressions;
    filtersModel: Record<string, unknown> | null;
    description: string;
    filtersSettingsId: number;
    category: string;
    routePath: string;
    users?: number[];
    groups?: number[];
  }): Promise<void> {
    try {
      if (!input?.moduleId) throw new Error('No module id');
      if (!input?.filtersSettingsId) throw new Error('No filters settings id');
      if (!input.filters) throw new Error('No filters');
      if (!input.description) throw new Error('No filters description');

      const user = this.auth.user();
      const tenant = this.auth.tenant();
      if (!user?.id) throw new Error('No user data');
      if (!tenant?.id) throw new Error('No tenant data');

      await firstValueFrom(
        this.graphQlClient.mutate<{ data?: Record<string, unknown> | undefined }>({
          mutation: insertFavorite,
          variables: {
            tenantId: tenant.id,
            userId: user.id,
            filters_settings_id: input.filtersSettingsId,
            moduleId: input.moduleId,
            description: input.description,
            filters: input.filters,
            filtersModel: input.filtersModel ?? {},
            category: input.category,
            routePath: input.routePath,
            users: input.users ?? [],
            groups: input.groups ?? [],
          },
        }),
      );

      this.messageService.showInfoSnackbar('Favorite saved');
    } catch (error) {
      this.messageService.showErrorSnackbar(`Error save favorite: ${error}`);
    }
  }

  async updateFavorite(input: {
    favoriteId: number;
    filters: FilterExpressions;
    filtersModel: Record<string, unknown> | null;
    description: string;
    users?: number[];
    groups?: number[];
  }): Promise<boolean> {
    try {
      if (!input.favoriteId) throw new Error('No Favorite id');
      if (!input.description) throw new Error('No Favorite description');

      const user = this.auth.user();
      if (!user?.id) throw new Error('No user data');

      await firstValueFrom(
        this.graphQlClient.mutate<{ data?: Record<string, unknown> | undefined }>({
          mutation: updateFavorite,
          variables: {
            id: input.favoriteId,
            filters: input.filters,
            filtersModel: input.filtersModel ?? {},
            description: input.description,
            users: input.users ?? [],
            groups: input.groups ?? [],
          },
        }),
      );

      this.messageService.showInfoSnackbar('Favorite updated');
      return true;
    } catch (error) {
      this.messageService.showErrorSnackbar(`Error update favorite to databse: ${error}`);
      return false;
    }
  }

  private getFavorites$(moduleId: number, subscription = false) {
    return this.auth.authInfo$.pipe(
      filter(({ user, tenant }) => !!user?.id && !!tenant?.id && !!moduleId),
      switchMap((s) => {
        const groups = `{${(s.tenant?.groups ?? []).map((g) => g.id).join(',')}}`;
        const vars = {
          tenantId: s.tenant?.id,
          userId: s.user!.id,
          moduleId: moduleId,
          tenantGroups: groups,
        };
        return (
          subscription
            ? this.graphQlClient.subscribe<{ data?: Favorite[] | undefined }>({
                query: favoritesSubscription,
                variables: vars,
              })
            : this.graphQlClient.query<{ data?: Favorite[] | undefined }>({
                query: favoritesQuery,
                variables: vars,
              })
        ).pipe(
          map((response) => response.data ?? []),
          catchError((e) => {
            console.error(`Error get Favorites for module: ${moduleId}`, e);
            return of([]);
          }),
        );
      }),
    );
  }

  private async recreatePeriodicDateFilters(favorite: Favorite): Promise<Favorite> {
    if (!favorite?.filters || !favorite?.filters_model || !favorite?.filters_setting?.filters) {
      return favorite;
    }

    const filtersModel = favorite.filters_model as Record<string, unknown>;

    // Find periodic date operator fields in the model
    const dateOperatorFields = Object.keys(filtersModel).filter(
      (key) =>
        key.endsWith('_date_operator') && typeof filtersModel[key] === 'string' && this.isDynamicDateOperator(filtersModel[key] as string),
    );

    if (dateOperatorFields.length === 0) {
      return favorite;
    }

    try {
      const dataContext = await this.filterExpressionService.getDataContext(this.auth.user()!, this.auth.tenant()!);
      const filterInstances = favorite.filters_setting?.filters?.map((config) => createFilter(config, dataContext));
      const result = await this.filterExpressionService.createGqlFilterExpressionsFromConfig(filterInstances, filtersModel);

      return {
        ...favorite,
        filters: {
          ...favorite.filters,
          whereExpressions: result.whereExpressions,
          filtersDescription: result.title,
        },
        filters_model: filtersModel,
      };
    } catch (error) {
      console.error('Error recreating filters:', error);
      return favorite;
    }
  }

  private isDynamicDateOperator(operator: string): boolean {
    return ['day', 'yesterday', 'week', 'month', 'year', 'previousWeek', 'previousMonth', 'time-today-gte', 'time-today-lte'].includes(
      operator,
    );
  }
}
